<template>
	<div>
		<v-dialog v-model="mapDialog" scrollable fullscreen persistent max-width="500px" transition="dialog-transition">
			<v-card>
				<v-toolbar dark color="primary">
					<v-btn icon dark @click="mapDialog = false">
						<v-icon>close</v-icon>
					</v-btn>
					<v-toolbar-title>Address Location</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn dark text @click="onSave()">Save</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<leaflet-map v-if="mapDialog" v-bind:lat-lng.sync="latLng" :draggable="true"></leaflet-map>
			</v-card>
		</v-dialog>
		<v-flex md6 xs12>
			<v-autocomplete
				ref="search"
				v-model="selectedAddress"
				:filter="searchResultFilter"
				label="Search Address"
				:items="searchItems"
				return-object
				:search-input.sync="searchText"
				placeholder="eg: 15 Church street, Cape Town"
			>
				<template slot="no-data">
					<v-list-item>
						No address found.
						<v-btn color="accent" @click="showDetails = true" :disabled="!enableManuelSearch">Enter Address Manually</v-btn>
					</v-list-item>
				</template>
			</v-autocomplete>
		</v-flex>
		<S2SFormGenerator v-if="showDetails" ref="addressForm" :schema="addressSchema" :data="addressModel" :apiLookup="lookupsApi"></S2SFormGenerator>
		<v-flex xs12 class="text-sm-right" v-show="showDetails">
			<v-btn color="accent" @click="onNext()">Next</v-btn>
		</v-flex>
	</div>
</template>

<script>
import Vue from "vue";

import LeafletMap from "@/components/LeafletMap.vue";
import addressSchema from "@/static/config/forms/address.json";
import { LookupsApi } from "@/api/lookups-api";

export default Vue.extend({
	name: "Addresses",

	components: { LeafletMap },

	computed: {
		geoSearchAddresses: function() {
			return this.$store.state.organisations.addresses.geoSearchAddresses;
		},
		searchItems: function() {
			if (this.geoSearchAddresses.length) {
				const result = this.geoSearchAddresses.map(entry => {
					const suburb = entry.Location.Address.County;
					const city = entry.Location.Address.City;
					const streetName = entry.Location.Address.Street;
					const streetNumber = entry.Location.Address.HouseNumber;
					const province = entry.Location.Address.State;
					const postalCode = entry.Location.Address.PostalCode;
					const name = entry.Location.Address.Label;
					const latitude = entry.Location.DisplayPosition.Latitude;
					const longitude = entry.Location.DisplayPosition.Longitude;
					let description = entry.Location.Address.Label;
					description += suburb ? ", " + suburb : "";

					this.latLng = { lat: latitude, lng: longitude };

					return {
						text: description,
						value: { name, country: "South Africa", province, city, suburb, streetName, streetNumber, postalCode, latitude, longitude }
					};
				});

				return result;
			} else {
				return [];
			}
		}
	},

	watch: {
		searchText: async function() {
			if (!this.searchText) return;
			try {
				await this.$store.dispatch("organisations/addresses/searchAddress", this.searchText);
				this.enableManuelSearch = true;
			} catch (error) {
				this.enableManuelSearch = true;
			}
		},
		selectedAddress: function(val) {
			if (val) this.showDetails = true;
			this.addressModel = { ...this.addressModel, ...val.value };
		}
	},

	data: function() {
		return {
			searchText: null,
			selectedAddress: null,
			addressSchema: addressSchema,
			addressModel: {},
			showDetails: false,
			mapDialog: false,
			enableManuelSearch: false,
			latLng: {},
			lookupsApi: LookupsApi.fetchLookups
		};
	},

	methods: {
		async onSave() {
			const form = this.$refs["addressForm"];
			const valid = await form.validate();
			if (!valid) return;
			this.$store.dispatch("organisations/addresses/createAddress", form.model);
			this.$store.dispatch("shopCreate/nextStage");
		},
		async onNext() {
			const form = this.$refs["addressForm"];
			const valid = await form.validate();
			// enter manually set country
			if (!form.model.country) {
				form.model.country = "South Africa";
				form.model.latitude = "-33.932106";
				form.model.longitude = "18.860151";
			}
			if (valid) this.mapDialog = true;
		},
		searchResultFilter() {
			return true;
		}
	}
});
</script>
