<template>
	<S2SForm title="Shop Create">
		<v-stepper :value="stage" cy-data="shop-create-stepper">
			<v-progress-linear :indeterminate="loading"></v-progress-linear>
			<v-stepper-header cy-data="stepper-header">
				<v-stepper-step :complete="stage > 1" step="1" :color="stage > 1 ? 'success' : 'accent'">Create Organisation</v-stepper-step>

				<v-divider></v-divider>

				<v-stepper-step :complete="stage > 2" step="2" :color="stage > 2 ? 'success' : 'accent'">Primary Contact</v-stepper-step>
				<v-divider></v-divider>

				<v-stepper-step :complete="stage > 3" step="3" :color="stage > 3 ? 'success' : 'accent'">Create Address</v-stepper-step>
			</v-stepper-header>

			<!-- STEPPER CONTENT -->
			<v-stepper-items>
				<!-- SHOP CREATION STEP 1 -->
				<v-stepper-content step="1" cy-data="stepper-content-1">
					<S2SFormGenerator ref="stepOneForm" :schema="shopCreateSchema" :apiLookup="lookupsApi"></S2SFormGenerator>

					<v-flex class="text-xs-right">
						<v-btn color="accent" @click="createStepOne()" cy-data="step-1-continue">Continue</v-btn>
					</v-flex>
				</v-stepper-content>

				<!-- USER SEARCH STEP 2 -->
				<v-stepper-content step="2" cy-data="stepper-content-2">
					<v-autocomplete
						cy-data="user-search"
						v-model="selectedUser"
						:loading="searchUserStatus.loading"
						:items="searchedUsers"
						:search-input.sync="search"
						cache-items
						class="mx-2 mb-2"
						text
						hide-no-data
						hide-details
						label="Search for user"
						item-text="email"
						return-object
					></v-autocomplete>

					<v-flex class="text-xs-right">
						<v-btn color="accent" @click="createStepTwo()" cy-data="step-2-continue">Continue</v-btn>
					</v-flex>
				</v-stepper-content>

				<!-- Address Create STEP 3 -->
				<v-stepper-content step="3">
					<address-create></address-create>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import AddressCreate from "@/components/AddressCreate.vue";
import shopCreateSchema from "../../static/config/forms/shop-create.json";
import { LookupsApi } from "@/api/lookups-api";

export default Vue.extend({
	name: "Shops",

	components: { AddressCreate },

	computed: {
		loading: function() {
			return this.$store.state.organisations.loading;
		},
		success: function() {
			return this.$store.state.organisations.success;
		},
		createdOrganisationId: function() {
			return this.$store.state.organisations.createdOrganisationId;
		},

		stage: function() {
			return this.$store.state.shopCreate.stage;
		},

		searchUserStatus: function() {
			return this.$store.state.user.searchUserStatus;
		},
		searchedUsers: function() {
			return this.$store.state.user.searchedUsers;
		}
	},

	watch: {
		search: function(searchVal) {
			this.searchDebounce(searchVal);
		},
		stage: function(state) {
			if (state > 3) this.$router.push({ name: "organisation-profile", params: { orgId: "" + this.createdOrganisationId } });
		},
		success: function(success) {
			if (success) this.$store.dispatch("shopCreate/nextStage");
			this.$store.dispatch("organisations/clearSuccess");
			if (this.stage > 3) this.$router.push({ name: "organisation-profile", params: { orgId: "" + this.createdOrganisationId } });
		},
		createdOrganisationId: function(val) {
			if (!val) return;
			this.$store.dispatch("organisations/fetchOrganisationById", val);
		}
	},

	data: function() {
		return {
			lookupsApi: LookupsApi.fetchLookups,
			shopCreateSchema: shopCreateSchema,
			search: null,
			selectedUser: {}
		};
	},

	mounted: function() {
		this.$store.dispatch("shopCreate/resetStage");
	},

	methods: {
		searchDebounce(searchVal) {
			const $this = this;
			setTimeout(function() {
				$this.$store.dispatch("user/searchUsersByEmail", searchVal);
			}, 500);
		},
		async createStepOne() {
			const stepOneForm = this.$refs["stepOneForm"];
			const isValid = await stepOneForm.validate();
			if (isValid)
				this.$store.dispatch("organisations/createOrganisation", {
					name: stepOneForm.model.name,
					types: [stepOneForm.model.types],
					industry: stepOneForm.model.industry
				});
		},
		createStepTwo() {
			this.$store.dispatch("organisations/addUserToOrganisationWithGroups", {
				organisationId: this.createdOrganisationId,
				user: this.selectedUser
			});
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}
</style>
